/* CARDS MODULE */
.cardWrapper {
	margin-bottom:50px;
}

.progressWrapper {
	display:flex;
	align-items: center;
	justify-content:center;
	width:100%;
	height:100%;
	border:0px solid pink;
	margin-bottom:50px;
}


.cardActions {
	justify-content:space-between;
}

.captionItem {

}

.captionText {
	display:flex;
	align-items: center;
	font-size:1em;
}

.detailsGraphic {
	width:16px;
}

.detailsGraphicSmall {
	width:8px;
}


.detailsIcon {
	 display:flex;
	 justify-content:center;
}

.detailsText {
	display:flex;
	align-items: center;
	font-size:0.8em;
}

.detailsTextList {
	margin:0px;
	display:flex;
	align-items: left;
	font-size:0.8em;	
}

.detailsTextList > ul {
	margin:0px;
	padding:0px;
	padding-left:15px;
	font-size:0.75em;
    list-style: none; /* Remove default bullets */
}

.detailsTextList > ul li::before {
  content: "\2022";  
  color: #B3863C; 
  font-weight: bold; 
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}


.userOnCard {
	width:100%;
}

.noCards {
	display:flex;
	border:1px solid pink;
	min-height: 60%; 
}