.cardUserBar {
	background-color: rgba(255, 255, 255, 0) !important;
}

.cardUserImg:hover {
	cursor:pointer;	
}

.myIconButton {
	background-color:#ffffff !important;	
	border:1px solid white !important;	
}

.myIconButton:hover {
	color:#B3863C !important;	
}


