/* MENU */
.menuBox {
	border:none;
	width:100%;
	margin-bottom:0px;
	padding-top:30px;
	padding-bottom:30px;
	z-index:1000;
	background-color:#ffeee3;
	position:fixed;
	top:0px;
}

.menuLogo {
	display:flex;
	text-decoration:none;
	font-size:1.4em;
	letter-spacing:0.5em;
	justify-content:flex-start;
	align-items:center;
}

.menuLogoMobile {
	display:flex;
	text-decoration:none;
	font-size:1.1em;
	letter-spacing:0.5em;
	justify-content:flex-start;
	align-items:center;
}


.menuUser {
	display:flex;
	justify-content:flex-end;
	align-items:center;
}

.menuUserName {
	font-weight:300;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;	
}

.menuWrap {
	display:flex;
	flex-direction:row;
	justify-content:flex-end;
	align-items:center;
	text-transform:uppercase;
}

.menuWrapMobile {
	display:flex;
	flex-direction:row;
	justify-content:flex-end;
	align-items:center;
	text-transform:uppercase;
}

.menuWrapUser {
	display:flex;
	flex-direction:row;
	justify-content:flex-end;
	align-items:center;
}


.menuItem {
	font-size: 1em;
	color: #B3863C;
	text-decoration:none;
	text-transform:uppercase;
	cursor: pointer;
}

.menuItemActive {
	font-size: 1em;
	color: #B3863C;
	text-decoration:underline 2px solid;
}