.cPromo1 {
	background-color:rgba(179, 134, 60, 0.2);
}

.promoImg1 {
	width:100%;
}

.promoTxt1 {
	font-size:1.2em;
}