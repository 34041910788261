BODY {
	background-color:#ffeee3;
}

MAIN {
	display:flex;
	flex-direction:column;
}

A {
	color:#B3863C;	
}

/* MAIN COLOR */
.c1 {background-color:#B3863C} 
.c1text {color:#B3863C}


/* HIGHLIGHT COLORS */


.c3 {background-color:#22B8A3}
.c3text {color:#22B8A3}

.c4 {background-color:#E95F00}
.c4text {color:#E95F00}

/* SHADES */
.c5 {background-color:#1D1D1D}
.c5text {color:#1D1D1D}

.c6 {background-color:#CBCBCB}
.c6text {color:#CBCBCB}

.c7 {
	background-color: rgba(179, 134, 60, 0.1);
}

.cWhite {background-color:#FFFFFF}


img {
	border-radius:10px;	
}




/* DEFAULT COLOR*/
h1, h2, h3, h4  {
	color:#B3863C;
	text-transform:uppercase;
	font-weight:400;
	letter-spacing:0.1em;
	line-height: 1.5em;
}

p, ul {
	color:#B3863C;
	font-weight:400;
	line-height: 1.5em;
}



