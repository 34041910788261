.hula {
	display:flex;
	border:5px solid black;
	background-color: white;
	margin: 100px;
}

.progressWrapper {
	display:flex;
	align-items: center;
	justify-content:center;
	width:100%;
	height:100%;
	border:0px solid pink;
	margin-bottom:50px;
}